import React, { useState } from 'react';
import { IconButton, Breadcrumbs, Card, CardContent, CircularProgress, Grid, Link, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, Chip } from '@material-ui/core';
import { GET } from './../../../auxiliares/consultar';
import ROUTES from '../../../navigation/routes';
import { useHistory } from 'react-router';
import CustomStyle from '../../../CustomStyles';

//import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
//import CreateIcon from '@material-ui/icons/Create';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import NumberFormat from 'react-number-format';

const classes = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    form: {
      '& .MuiTextField-root': {
        width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth:'100%',
      left:-7,
      padding:0
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    container : {height:'400px'}
  }));



const columns = [
    { id: 'id', label: 'ID', minWidth: 20 },
    { id: 'negocio', label: 'Negocio', minWidth: 100 },
    { id: 'codigo', label: 'Codigo', minWidth: 50, align: 'center' },
    { id: 'monto', label: 'Monto', minWidth: 50, align: 'right' },
    { id: 'entrega', label: 'Entrega', minWidth: 50, align: 'center' },
    { id: 'estatus', label: 'Estatus', minWidth: 100, align: 'center' },
    { id: 'acciones', label: 'Acciones', minWidth: 130, align: 'center' },
  ];


    function PedidoEstatus(props)
    {
        let estatus = props.estatus;
        var color = '';
        var colorLetras = '';

        if(estatus === 'Rechazado')
        {
            color = CustomStyle.Colors.gris;
            colorLetras = '#000'
        }
        if(estatus === 'Enviado')
        {
            color = CustomStyle.Colors.primary;
            colorLetras = '#FFF'
        }
        if(estatus === 'Aprobado' || estatus === 'Por entregar' || estatus === 'En camino' )
        {
            color = '#ffc801';
            colorLetras = '#000'
        }
        if(estatus === 'Entregado'  )
        {
            color = CustomStyle.Colors.success;
            colorLetras = '#fff'
        }
            
        
        return <Chip label={estatus} style={{background:color,color:colorLetras}}/>
    }


  


export default function Pedidos({routes,navigation})
{
    const [data,setData] = useState([]);
    const [progress,setProgress] = useState(false);
    const [page,setPage] = useState(0);
    const [rowsPerPage,setRowsPerPage] = useState(5);

    var PEDIDOS = [];

    React.useEffect(()=>{
        
        GetPedidos()
        //GETPEDIDOS()

    },[]);

    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    async function GetPedidos()
    {
        setProgress(true)
        var datos = null;
        datos = await GET(ROUTES.PEDIDOS.All);
        await setData(datos);
        PEDIDOS = await datos.pedidos;
        setProgress(false);
    }


    function handleChangePage(event, newPage){
        setPage(newPage);
    };

    function handleChangeRowsPerPage(event){
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    function PEDIDOSROW(props)
    {
        var items = props.pedidos;

        if(props.pedidos !== undefined)
        {
            return items.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map(function(pedido){
                return(
                    <TableRow key={pedido.id}>
                        <TableCell>
                            {pedido.id}
                        </TableCell>
                        <TableCell>
                            {pedido.negocio}
                        </TableCell>
                        <TableCell align='center'>
                           {pedido.codigo}
                        </TableCell>
                        <TableCell align='right' >
                            <NumberFormat value={pedido.monto} displayType={'text'} thousandSeparator={true} renderText={ (value,props) => <Typography>{value} {pedido.moneda}</Typography>} />
                        </TableCell>
                        <TableCell align='center'>
                           {pedido.entrega}
                        </TableCell>
                        <TableCell align='center'>
                            <PedidoEstatus estatus={pedido.estatus}/>
                        </TableCell>
                        <TableCell align='center'>
                            <Link onClick={()=>{
                                    localStorage.setItem('pedido',JSON.stringify(pedido));
                                    redireccionar('pedidos_detalles');
                                }}
                            >
                                <IconButton>
                                    <FindInPageIcon style={{color:CustomStyle.Colors.primaryColor500}} />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </TableRow>
                )
            })
        }

        return null;
    }



    return(
        <div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginBottom:20}}>
                <Breadcrumbs style={{color:CustomStyle.Colors.gris2}} >
                    <Link style={{color: CustomStyle.Colors.gris2, cursor:'pointer' }} onClick={() => redireccionar('/')}>Dashboard</Link>
                    <Typography color="textPrimary">Pedidos</Typography>
                </Breadcrumbs>
            </div>
            
            <Grid>
                <Card>
                    <CardContent>
                        PEDIDOS
                        {
                            progress === true
                            ? 
                            <div style={{display:'flex',width:'100%',alignItems:'center',paddingBottom:'25px', justifyContent:'center'}}>
                                <CircularProgress/>
                            </div>
                            :
                            <>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table" size='small' >
                                        <TableHead>
                                            <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth,backgroundColor:CustomStyle.Colors.white, color:'#000',fontWeight:'bold' }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <PEDIDOSROW pedidos={data.pedidos} page={page} rowsPerPage={rowsPerPage} />
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5,10,15]}
                                    component="div"
                                    count={data.pedidos !== undefined ? data.pedidos.length : 0 }
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={ handleChangePage}
                                    onChangeRowsPerPage={ handleChangeRowsPerPage}
                                />
                            </>
                        }
                        
                    </CardContent>
                </Card>
            </Grid>
            
        </div>
    )
}
