import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, TextField, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
  }));
  



export default function TasaCambio()
{
    const [tasa,setTasa] = useState(100);
    const classes = useStyles();

    return(
        <Container component="main" maxWidth="xs">
            <Typography variant='h4' align='center' >Tasa de Cambio</Typography>
            <Grid container justify='center' alignContent='center' direction='column'>
                <div className={classes.paper}>
                    <form className={classes.form}>
                        <TextField
                            autoFocus
                            variant='outlined'
                            label='Tasa de Cambio'
                            value={tasa}
                        />
                    </form>
                </div>
            </Grid>
        </Container>
    )
}