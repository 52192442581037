

/**
 * Estas Rutas son las rutas principales de la Main web de QUD
 * Habilitarlas antes de subir la actualizacion al servidor*/
 


 const URL = 'https://api.qud.life/';
 const API = 'api/v1/';
 
 const url = URL + API;
 const urlAdmin = URL + API + 'admin/';
 const URLImages = URL + 'apiqud/public/storage/img/';
 
 
 /**
  * Estas Rutas son las rutas en LOCAL de QUD
  * comentarlas antes de subir la actualizacion al servidor
 *
 const URL = 'http://localhost/qud/public/';
 const API = 'api/v1/';
 
 const url = URL + API;
 const urlAdmin = URL + API + 'admin/';
 const URLImages = URL + 'storage/img/';

 
 /** AQUI TERMINAN LAS RUTAS PARA PRUEBAS LOCALES */
 
 
const ROUTES = {
    URLImagenes : URLImages,
    GOOGLE : 
    {
        GOOGLE_MAPS_APIKEY : 'AIzaSyDQSZlmEUm2SQtC82bzMD-ewSbtWYgx8og'
    },
    AUTH : 
    {
        Register : url + 'register',
        CompanySignup : url + 'companysignup',
        Login : url + 'login',
        Verify : url + 'register/email_verification',
    },
    PEDIDOS : 
    {
        All : urlAdmin + 'pedidos/all',
        Details : urlAdmin + 'pedidos/details/', //hay que agregar el id del pedido
    }
 
}
 
 export default ROUTES;