

export async function GET(ruta)
{
    try
    {
        var token = 'Bearer '+ localStorage.getItem('userToken');
        let response = await fetch(
            ruta,
            {
                method : 'GET',
                headers : 
                {
                    'Accept' : 'application/json',
                    'Authorization' : token,
                }
            }
        );

        let responseJson = await response.json();
        console.log(responseJson);

        if(responseJson.success == true )
        {
            return responseJson.data;
        }

        if(responseJson.success === false)
        {
            return responseJson.message;
        }

    }
    catch(error)
    {
        return error;
    }
}


export async function POST(ruta)
{

}
