
const CustomStyle = {
    Colors : 
    {
        /*primaryColor : '#133863',*/
        primary : '#d52236', //#ffe16b
        primary2 : '#000',
        primary900 : '#E65100',
        primary700 : '#1A4170',
        primary600 : '#234A7B',
        primary500 : '#2A5283',
        primary200 : '#95A6BE',
        primary100 : '#BEC9D8',

        primaryContrast : '#FFF4F4',

        purpura50 : '#F3E5F5',
        purpura500 : '#9C27B0',
        purpura700 : '#7B1FA2',

        MorocotasPrimary : '#018e49',

        secondary : '#a02b33',
        secondary200 : '#89BEDB',
        secondaryContrast : '#827474',

        white : '#FFF',
        black : '#000',
        gris : '#EEE',
        grisForm : '#4b4b4b',
        gris2 :'#646464',
        grisTexto : '#999999',

        titutlos : '#1b2e35',

        error : '#B00020',
        success : '#28a745',
        formIcon : '#BDBDBD',
        VESText : '#524214',
        
        azulGris : '#607D8B',
        azul : '#BDBDBD'
    },

    Subtitle: {
        fontWeight:'bold',
        lineHeight:1.8,
        letterSpacing:0.7,
        color:'#212121'
    },
    Text:{
        lineHeight:1.8,
        letterSpacing:0.7,
        color:'#212121'
    },
    highlighted : {
        color:'#133b65',
        fontWeight:'bold'
    },
    
    BackgroundColors : 
    {
        primary : { backgroundColor : '#143763'} 
    },
    
    backGroundGris: {
        backgroundColor:'#EEE'
    },

    ButtonPrimary : {
        backgroundColor : '#FFB74D',
        color : '#000'
    },

    row : {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center'
    }
}

export default CustomStyle;