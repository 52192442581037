import React, { Component, useState } from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import { Typography, Grid, Paper, TextField, Button, CircularProgress } from '@material-ui/core';

import { Clear, Create, Save } from '@material-ui/icons/';

import ROUTES from '../../../navigation/routes';

import { Alert, AlertTitle } from '@material-ui/lab';
//import CustomStyle from '../../../CustomStyles';

const containerStyle = {
    position: 'relative',  
    width: 700,
    height: 400
};





function MapaPedido(props)
{
    const [center,setCenter] = useState({lat:10.153135,lng:-64.678742})
    const [origen,setOrigen] = useState();
    const [destino,setDestino] = useState();

    React.useEffect(()=>{
        
        setOrigen(props.origen);
        setDestino(props.destino)
        console.log(props)

    },[props]);

    return(
        
            <Map
                google = {window.google}
                zoom={13}

                initialCenter={{
                    lat: center.lat,
                    lng: center.lng
                }}
                center = {{
                    lat: center.lat,
                    lng: center.lng
                }}
                containerStyle={containerStyle}
            >

                {
                    origen !== undefined
                    ?
                        <Marker
                            position={{lat: origen.lat, lng: origen.lng}} 
                        />
                    :null
                }
                {
                    destino !== undefined
                    ?<Marker position={{lat: destino.lat, lng: destino.lng}} />    
                    :null
                }
            </Map>
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyB1vPO8CLBmZc6ba1pRhmhzbz_zZe6QImE'
  })(MapaPedido)