import { Switch, Route } from 'react-router-dom';

import SignIn from '../views/auth/signIn';

export default function AuthNavigation()
{
    return(
        <Switch>
            <Route exact path='/'>
                <SignIn/>
            </Route>
        </Switch>
    );
}