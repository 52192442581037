import { Switch } from 'react-router-dom';

import AppRoute from './AppRoute';
import DashboardLayout from './../layouts/dashboarLayout';

import Dashboard from './../views/admin/index';
import Hola from './../views/admin/hola';
import TasaCambio from '../views/admin/tasa';

/** PEDIDOS */
import Pedidos from '../views/admin/pedidos/index'
import PedidosDetalles from '../views/admin/pedidos/detalle';

export default function AuthNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={DashboardLayout} component={Dashboard} />
            <AppRoute path='/hola' layout={DashboardLayout} component={Hola} />
            <AppRoute path='/tasacambio' layout={DashboardLayout} component={TasaCambio} />
            <AppRoute path='/pedidos' layout={DashboardLayout} component={Pedidos} />
            <AppRoute path='/pedidos_detalles' layout={DashboardLayout} component={PedidosDetalles} />
        </Switch>
    );
}