import React, { useState, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import CustomStyles from './../../CustomStyles';
import HQUD from './../../images/HQUD.png';

import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AuthContext from './../../navigation/AuthContext';
import { CircularProgress } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: CustomStyles.Colors.secondary,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: '#0066ff',
        main: '#0044ff',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
  });

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://qud.life/">
        QUD LIFE
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: CustomStyles.Colors.secondary,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const initialState = 
    {
        email : '',
        password : '',
        errorEmail : false,
        errorPassword : false,

        progress : false,
        errorLogin : false,
        errorLoginMsg : '',
        login : false,
        id : 0
    }

function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}


export default function SignIn() {
  

    const [success, setSuccess] = React.useState(false);
    const [state,dispatch] = useReducer(reducer,initialState);
    const { idTipoUsuario, email, password, errorEmail, errorPassword, progress, errorLogin, errorLoginMsg, login, id } = state;

    
    const { signIn } = React.useContext(AuthContext);


    React.useEffect(() => {

        dispatch({field:'progress',value:false})
    }, []);

    const onChange = (e) => {
        dispatch({field : e.target.name, value : e.target.value});
    }
  
    const classes = useStyles();

    const formVerification = () => {

        var ban = 0;

        if(email === '')
        {
            dispatch({field:'errorEmail',value:true});
            ban = 1;
        }
        else
            dispatch({field:'errorEmail',value:false});

        if(password === '')
        {
            dispatch({field:'errorPassword',value:true});  
            ban = 1;
        }
        else
            dispatch({field:'errorPassword',value:false});  
        
        return ban;

    }


    async function handleSubmit()
    {
        if(formVerification() === 0)
        {
            await dispatch({field:'progress',value:true})
            await signIn({email,password});
            await dispatch({field:'progress',value:false})
        }
    }



  return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <form className={classes.form} noValidate>
                <ThemeProvider theme={theme}>
                    <TextField
                        error = {errorEmail === true ? true : false}
                        variant="outlined"
                        margin="normal"
                        required = {true}
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        type="email"
                        value={email}
                        onBlur={ () => (email === '' ? dispatch({field:'errorEmail',value:true}) : dispatch({field:'errorEmail',value:false}) ) }
                        onChange = {onChange}
                        autoComplete="email"
                        helperText={email === '' ? 'Ingrese el correo electrónico' : null}
                        autoFocus
                    />
                    
                    <TextField
                        error = {errorPassword === true ? true : false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onBlur={ () => (password === '' ? dispatch({field:'errorPassword',value:true}) : dispatch({field:'errorPassword',value:false}) ) }
                        onChange = {onChange}
                        autoComplete="current-password"
                        helperText={password === '' ? 'Ingrese la contraseña' : null}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled = {progress}
                        //className={classes.submit}
                        onClick= { () => handleSubmit() }
                    >
                        {
                            progress == true
                            ? <CircularProgress color='#FFF' />
                            : 'Iniciar Sesion'
                        }
                    </Button>

                    <Grid container>
                        <Grid item xs>
                        <Link href="#" variant="body2">
                            Forgot password?
                        </Link>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </form>
        </div>
        <Box mt={8}>
            <Copyright />
        </Box>
    </Container>
  );
}