import React from 'react';
import { useHistory } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import LocalOffer from '@material-ui/icons/LocalOffer'
import SettingsIcon from '@material-ui/icons/Settings';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';


  



export default function MainListItems(){

    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    return(
        <div>            
            <ListItem button onClick={() => redireccionar('/') }>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Inicio" />
            </ListItem>

            <ListItem button onClick={() => redireccionar('/pedidos') }>
                <ListItemIcon>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Pedidos" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('hola') } > 
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Configuración" />
            </ListItem>

            <ListItem button onClick={() => redireccionar('/tasacambio') }>
                <ListItemIcon>
                    <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText primary="Tasa de cambio" />
            </ListItem>
        
            
            
        
            <ListItem button>
                <ListItemIcon>
                <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItem>

            <ListItem button>
                <ListItemIcon>
                <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Integrations" />
            </ListItem>
        </div>
    )
    
}
