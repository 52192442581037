import { Breadcrumbs, Card, CircularProgress, Grid, Link, Step, StepLabel, Stepper, Typography,
        Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, makeStyles, Button, Modal,} from '@material-ui/core';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router';
import CustomStyle from '../../../CustomStyles';
import ROUTES from '../../../navigation/routes';
import { GET, REDIRECCIONAR } from './../../../auxiliares/consultar';
import EstatusS from './estatusS';
import MapaPedido from './mapa';

const classes = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    container : {height:'400px'}
  }));

const columns = [
    { id: 'idDetalle', label: 'ID', minWidth: 20 },
    { id: 'producto', label: 'Producto', minWidth: 100, align:'center' },
    { id: 'cantidad', label: 'Cantidad', minWidth: 50, align: 'center' },
    { id: 'precio', label: 'Precio', minWidth: 50, align: 'right' },
    { id: 'monto', label: 'Monto', minWidth: 50, align: 'right' }
  ];


export default function PedidosDetalles()
{
    const [pedido,setPedido] = useState([]);
    const [detalles,setDetalles] = useState([]);
    const [open,setOpen] = useState(false);
    let history = useHistory();

    React.useEffect(()=>{
        
        GetPedido()

    },[]);

    async function GetPedido()
    {
        var Pedido = await JSON.parse(localStorage.getItem('pedido'));
        var Detalles = await GET(ROUTES.PEDIDOS.Details+Pedido.id);
        setPedido(Pedido)
        setDetalles(Detalles.detalles);
        console.log(Pedido);
    }


    function DETALLES()
    {

        if(detalles !== [])
        {
            return detalles.map(function(detalle){
                return(
                    <TableRow key={detalle.idDetalle}>
                        <TableCell>
                            {detalle.idDetalle}
                        </TableCell>
                        <TableCell align='center'>
                            {detalle.producto}
                        </TableCell>
                        <TableCell align='center'>
                            {detalle.cantidad}
                        </TableCell>
                        <TableCell align='right' >
                            <NumberFormat value={detalle.precio} displayType={'text'} thousandSeparator={true} renderText={ (value,props) => <Typography>{value}</Typography>} />
                        </TableCell>
                        <TableCell align='right'>
                            <NumberFormat value={detalle.monto} displayType={'text'} thousandSeparator={true} renderText={ (value,props) => <Typography>{value}</Typography>} />
                        </TableCell>
                    </TableRow>
                )
            })
        }

        return null;
    }  
    

    
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }


    return(
        <div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginBottom:20}}>
                <Breadcrumbs style={{color:CustomStyle.Colors.gris2}} >
                    <Link style={{color:CustomStyle.Colors.gris2, cursor:'pointer' }} onClick={()=> redireccionar('/') }>Dashboard</Link>
                    <Link style={{color:CustomStyle.Colors.gris2, cursor:'pointer' }} onClick={()=> redireccionar('/pedidos') }>Pedidos</Link>
                    <Typography color="textPrimary">Detalle</Typography>
                </Breadcrumbs>
            </div>
            {
                pedido === []
                ?
                    <CircularProgress color={CustomStyle.Colors.primary} />
                :
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='h5' align='center'>{pedido.codigo}</Typography>
                        </Grid>
                        {
                            pedido.estatus === 'Rechazado'
                            ?
                            <Grid item xs={12}>
                                <Typography variant='h6' align='center' style={{color:CustomStyle.Colors.primary}}>{pedido.estatus}</Typography>
                            </Grid>
                            
                            :
                            <Grid item xs={12}>
                                <EstatusS estatusN={pedido.estatusN}/>
                            </Grid>
                        }
                        
                        <Grid container justify='center'>
                            <Grid item xs={11}>
                                {/** CARD para datos basicos del pedido */}
                                <Card style={{padding:20}}>
                                    <Grid container>
                                        <Grid item xs={12} lg={6} xl={6}>
                                            <div style={{display:'flex',flexDirection:'row'}}>
                                                <Typography variant='subtitle2'>Fecha:&nbsp;</Typography>
                                                <Typography  style={{color:CustomStyle.Colors.gris2}}>{pedido.fecha}</Typography>
                                            </div>
                                            <div style={{display:'flex',flexDirection:'row'}}>
                                                <Typography variant='subtitle2'>Comercio:&nbsp;</Typography>
                                                <Typography  style={{color:CustomStyle.Colors.gris2}}>{pedido.negocio}</Typography>
                                            </div>
                                            <div style={{display:'flex',flexDirection:'row'}}>
                                                <Typography variant='subtitle2'>Cliente:&nbsp;</Typography>
                                                <Typography style={{color:CustomStyle.Colors.gris2}}>{pedido.cliente}</Typography>
                                            </div>
                                            {
                                                pedido.entrega === 'Delivery'
                                                ?
                                                <div style={{display:'flex',flexDirection:'row'}}>
                                                    <Typography variant='subtitle2'>Monto:&nbsp;</Typography>
                                                    <NumberFormat value={pedido.monto} displayType={'text'} thousandSeparator={true} renderText={ (value,props) => <Typography style={{color:CustomStyle.Colors.gris2}}>{value} {pedido.moneda}</Typography>} />
                                                </div>
                                                :null
                                            }
                                        </Grid>

                                        <Grid item xs={12} lg={6} xl={6}>
                                            {
                                                pedido.entrega === 'Delivery'
                                                ? null
                                                :
                                                <div style={{display:'flex',flexDirection:'row'}}>
                                                    <Typography variant='subtitle2'>Monto:&nbsp;</Typography>
                                                    <NumberFormat value={pedido.monto} displayType={'text'} thousandSeparator={true} renderText={ (value,props) => <Typography style={{color:CustomStyle.Colors.gris2}}>{value} {pedido.moneda}</Typography>} />
                                                </div>
                                            }
                                            
                                            <div style={{display:'flex',flexDirection:'row'}}>
                                                <Typography variant='subtitle2'>Tipo de entrega:&nbsp;</Typography>
                                                <Typography style={{color:CustomStyle.Colors.gris2}}>{pedido.entrega}</Typography>
                                            </div>

                                            {
                                                pedido.entrega === 'Delivery'
                                                ?
                                                <div style={{display:'flex',flexDirection:'row'}}>
                                                    <Typography variant='subtitle2'>Monto Delivery:&nbsp;</Typography>
                                                    <NumberFormat value={pedido.montoDelivery} displayType={'text'} thousandSeparator={true} renderText={ (value,props) => <Typography style={{color:CustomStyle.Colors.gris2}}>{value} {pedido.moneda}</Typography>} />
                                                </div>
                                                :null
                                            }

                                            <div style={{display:'flex',flexDirection:'row'}}>
                                                <Typography variant='subtitle2'>Dirección:&nbsp;</Typography>
                                                <Typography style={{color:CustomStyle.Colors.gris2}}>{pedido.direccion} &nbsp;
                                                    <Button variant='outlined' onClick={()=> setOpen(true)} >Ver Mapa</Button> 
                                                    <Modal
                                                        open = {open}
                                                        onClose={()=> setOpen(false)}
                                                        aria-labelledby="simple-modal-title"
                                                        aria-describedby="simple-modal-description"
                                                        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                                                    >
                                                        <div 
                                                            style={{width:700,height:400,background:'#fff',display:'flex',flexDirection:'row',justifyContent:'center'}}
                                                        >
                                                            {
                                                                pedido.entrega === 'Pick-up'
                                                                ?
                                                                <MapaPedido origen={undefined} destino={{lat:pedido.negocioLat,lng:pedido.negocioLng}} />
                                                                :
                                                                <MapaPedido origen={{lat:pedido.negocioLat,lng:pedido.negocioLng}} destino={{lat:pedido.latitud,lng:pedido.longitud}} />
                                                            }
                                                            
                                                        </div>
                                                    </Modal>
                                                </Typography>
                                            </div>
                                            
                                        </Grid>
                                    </Grid>
                                </Card>
                                
                                {/** CARD para los detalles del pedido */}
                                <Card style={{padding:20,marginTop:20}}>
                                    <Typography variant='h6'>Detalles</Typography>
                                    <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table" size='small' >
                                        <TableHead>
                                            <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth,backgroundColor:CustomStyle.Colors.white, color:'#000',fontWeight:'bold' }}
                                                >
                                                    
                                                    {
                                                        column.label === 'Precio' || column.label === 'Monto'
                                                        ? column.label + ' (' +pedido.moneda +')'
                                                        : column.label
                                                    }
                                                </TableCell>
                                            ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <DETALLES />
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                </Card>
                            </Grid>
                        </Grid>

                    </Grid>
            }
        </div>

    )
}