import React from "react";
import { BrowserRouter, Switch, Route, Link, useHistory } from "react-router-dom";

import AuthContext from './navigation/AuthContext';
import ROUTES from './navigation/routes';

import AuthNavigation from './navigation/authNavigation';
import DashboardNavigation from './navigation/dashboardNavigation';



import { Button, IconButton, Snackbar } from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';

//import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CustomStyles from './CustomStyles';
import CustomStyle from "./CustomStyles";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";

const theme = createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: CustomStyles.Colors.primary,
        // dark: will be calculated from palette.primary.main,
        contrastText: CustomStyle.Colors.primaryContrast //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: '#0066ff',
        main: '#fff',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#827474',
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      text : {
          
          secondary : CustomStyle.Colors.primaryContrast
      }
    },
  });





export default function App({ navigation }) {

    const [open,setOpen] = React.useState(false);
    const [message,setMessage] = React.useState('');

    const [state, dispatch] = React.useReducer(
        (prevState, action) => 
        {
            switch (action.type) 
            {
                case 'RESTORE_TOKEN':
                    return {
                        ...prevState,
                        userToken: action.token,
                        isLoading: false,
                    };
                case 'SIGN_IN':
                    return {
                        ...prevState,
                        isSignout: false,
                        userToken: action.token,
                    };
                case 'SIGN_OUT':
                    return {
                        ...prevState,
                        isSignout: true,
                        userToken: null,
                    };
            }
        },
        {
            isLoading: false,
            isSignout: false,
            userToken: null,
        }
    );

    React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
        
        const bootstrapAsync = async () => {
            let userToken;

            try
            {
                userToken = await localStorage.getItem('userToken');
            }
            catch (e) {
                // Restoring token failed
            }

            // After restoring token, we may need to validate it in production apps
            

            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
            dispatch({ type: 'RESTORE_TOKEN', token: userToken });
        };

        bootstrapAsync();
    }, []);

    const authContext = React.useMemo(
        () => ({
            signIn: async data => 
            {
                // In a production app, we need to send some data (usually username, password) to server and get a token
                // We will also need to handle errors if sign in failed
                // After getting token, we need to persist the token using `AsyncStorage`
                // In the example, we'll use a dummy token
                await console.log('Iniciando en SignIn');
                
                var dataErrors = 0;
                var params = new FormData();
                params.append('email',data.email);
                params.append('password',data.password);

                try 
                {
                    let response = await fetch(
                        ROUTES.AUTH.Login,
                        {
                            method : 'POST',
                            headers : 
                            {
                                'Accept' : 'application/json',
                                //'Content-Type' : 'multipart/form-data'
                            },
                            body : params
                        }
                    );

                    let responseJson = await response.json();
                    console.log(responseJson)
                    if(responseJson.message === 'The given data was invalid.')
                    {
                        setMessage('Verifique el email y password ingresados');
                        setOpen(true);
                    }
                    

                    if(responseJson.success === false)
                    {
                        
                        setMessage(responseJson.message);                        
                        
                        /**
                         * AQUI SE DEBE MOSTRAR UN MENSAJE DE ERROR
                         */
                        setOpen(true);
                    }
                    
                    if(responseJson.success === true)
                    {
                        try
                        {
                            await localStorage.setItem('userToken',responseJson.data.token);
                            await localStorage.setItem('user',responseJson.data.user);

                            dispatch({ type: 'SIGN_IN', token: responseJson.data.token });
                            //dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' });
                        }
                        catch(error)
                        {
                            console.log(error);
                        }
                    } 
                    
                } 
                catch (error) 
                {
                    setMessage('Comuniquese con el quipo de soporte');                        
                        
                        /**
                         * AQUI SE DEBE MOSTRAR UN MENSAJE DE ERROR
                         */
                        setOpen(true);
                    console.log(error)
                }
                
                //dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' });
            },
            signOut: () => {
                
                console.log('Cerrando sesion')
                dispatch({ type: 'SIGN_OUT' })
                
            } ,
            signUp: async data => {
                // In a production app, we need to send user data to server and get a token
                // We will also need to handle errors if sign up failed
                // After getting token, we need to persist the token using `AsyncStorage`
                // In the example, we'll use a dummy token

                dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' });
            },
        }),
        []
    );

    if (state.isLoading) {
        // We haven't finished checking for the token yet
        //monstrar un btn de carga
        //return <AppLoading/>;
        console.log('Cargando');
    }

    return (
        <BrowserRouter>

            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={open}
                autoHideDuration={7000}
                onClose={()=> setOpen(false)}
                message={message}
                action={
                <React.Fragment>
                    <Button color="primary" style={{color:'#FFF'}} size="small" onClick={()=> setOpen(false)}>
                        OK
                    </Button>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={()=> setOpen(false)}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />

             
            
            <AuthContext.Provider value={authContext}>
                <ThemeProvider theme={theme} >
                {
                    state.userToken == null 
                    ?<AuthNavigation/>
                    :<DashboardNavigation/>
                }
                </ThemeProvider>
                
            </AuthContext.Provider>
        </BrowserRouter>
    );
}
